<template>
    <div style="min-width: 1360px">
      <Header/>
      <headerBanner :content="bannerContent" title="ERP企业资源系统" @btnClick="bannerClick" imgStyle="height:0%;height:0"
      :price="price" contentStyle="width:100%" :bodyStyle="bodyStyle"/>
      <div class="card">
        <div class="card-container">
          <div class="center-content">
            <div class="left">
              <div>
                <div class="left-title">降低商家履约成本</div>
                <div class="left-tips">快递仓库成本最优 仓储拣货路径最短</div>
              </div>
              <div>
                <div class="left-title">提升作业协同效率</div>
                <div class="left-tips">订单智能策略 波次/爆款打单</div>
              </div>
              <div>
                <div class="left-title">提高企业管理效率</div>
                <div class="left-tips">员工绩效 科学决策 数据报表</div>
              </div>
            </div>
            <div>
              <img :src="require('@/assets/img/home/erpCard.png')" alt="" style="width: 774px;height: 397px;object-fit: cover;">
            </div>
          </div>
        </div>
      </div>
      <div class="card" style="background-color: #F7F8FE;">
        <div class="card-container">
          <div style="text-align: center;font-size: 30px;font-weight: bold;margin-bottom: 30px;">为企业经营赋能，实现降本增效</div>
          <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;">
            <div class="cell-card" v-for="(item,i) in  dataList" :key="i">
              <div style="font-size: 18px;font-weight: bold;margin-bottom: 16px;">{{ item.title }}</div>
              <div style="font-size: 14px;color: #333;padding-right: 20px;text-align: justify;line-height: 1.8;">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-container">
          <div style="text-align: center;font-size: 24px;font-weight: bold;">专业贴心的服务流程</div>
          <div class="proBox">
            <div class="proBox-card" v-for="(item,i) in dataList2" :key="i">
              <img :src="item.icon" style="height: 105px;object-fit: contain;">
              <div class="proBox-head">{{ item.head }}</div>
              <span style="margin: 10px 0;font-size: 16px;" v-for="text in item.textArrar" :key="text">{{ text }}</span>
            </div>
          </div>
        </div>
      </div>
      <applyTrial ref="dialog" :servicePlatform="'ERP企业资源系统'"/>
      <Footer/>
    </div>
  </template>
  <script>
  import Header from "@/views/home/newCloudeHeader.vue";
  import applyTrial from "@/components/assembly/applyTrial";
  import Footer from "@/views/home/newCloudeFooter.vue";
  import headerBanner from '@/components/assembly/headerBanner';
  export default {
    components: {Header, Footer, headerBanner, applyTrial},
    data() {
      return {
        price:'市场价格：410000元',
        bannerContent: '实现对整个供应链的有效管理，包括采购、库存、销售、财务等各个环节，提高供应链的效率和降低成本。实现对企业资源的集成和管理，包括客户、项目、合同、人力资源等各类资源，提高资源的利用率和价值。',
        bodyStyle: `background-image:url(${require('@/assets/img/home/erpBanner.png')});background-size: 40%;background-repeat: no-repeat;background-position: right 25px;`,
        dataList: [
          {
            title: '多维商品管理，适配多行业特性',
            content: '支持多种商品特性管理，灵活适配不同主流行业商品管理要求，并可通过报表进行追溯查询，实现差异化的品类管理及分析，供应链管理更聚焦'
          },
          {
            title: '快速开单，省时省力',
            content: '支持跨单据复制，快速实现单据类型切换 录单快速粘贴、批量填充、扫码录入，多种方式便捷开单，支持商品多维度模糊搜索，实现快速查询，智能选仓，提高开单效率，保证开单质量'
          },
          {
            title: '灵活价格体系，快速响应市场变化',
            content: '开单取价支持按用户自定义的优先级自动取价，支持个性化价格方案设置，灵活满足不同市场营销策略或商品价格管理，快速准确开单不再是烦恼'
          },
          {
            title: '多维度成本核算，清晰可控',
            content: '支持按批号、辅助属性核算成本，分仓管理，商品成本计算方法随时可调整 特殊出入库业务，支持成本来源设置取值，提供定时成本核算方案、成本分析、成本调整'
          },
          {
            title: '专业数据分析，赋能经营决策',
            content: '人员绩效分析，随时跟踪销售情况，激励销售人员完成目标客户分析，基于RFM模型帮助企业进行客户分层管理 经营监控，为您监控每日经营数据，预警数据定时提醒，及时发现企业经营风险'
          },
          {
            title: '企业内外部协作，合作紧密高效',
            content: '通过多级审批实现分节点的操作确认，降低单一环节人员操作或决策失误造成的风险与损失 通过企业互联，实现往来上下游主体间单据的快速流转，降低沟通及录单成本，提高供应链协同效率'
          },
          {
            title: '精细库存管理，货仓打理更轻松',
            content: '支持冻结库存，分仓管理及核算，并提供灵活的库存计算方式，满足各行业的差异化库存管理模式 多种预警报表，实现对交期、库存、资金等预警风控管理，让您放心买卖，提升仓库周转率。'
          },
          {
            title: '往来清晰，资金流更健康',
            content: '应收应付预警、往来对账、账龄分析，解决企业对账难题 购销费用透明化、费用分摊精准，经营利润报表一目了然'
          }
        ],
        dataList2: [
          {
            icon: require('@/assets/img/home/erp-icon1.png'),
            head: '售前',
            textArrar: ['痛点咨询', '演示讲解', '案例分享', '解决方案规划']
          },
          {
            icon: require('@/assets/img/home/erp-icon2.png'),
            head: '售中',
            textArrar: ['实地调研', '方案设计', '驻场实施', '培训上手']
          },
          {
            icon: require('@/assets/img/home/erp-icon3.png'),
            head: '售后',
            textArrar: ['7*24小时系统保障', '7*12小时在线客服', '定期售后回访', '每月在线培训']
          }
        ]
      }
    },
    methods: {
      bannerClick() {
        this.$refs.dialog.show = true;
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .card {
    padding: 60px 0;
    &-container {
      width: 1200px;
      margin: auto;
    }
  }
  .center-content {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .left-title {
        font-size: 24px;
        font-weight: bold;
        color: #333;
      }
      .left-tips {
        font-size: 16px;
        color: #666;
        margin-top: 14px;
      }
    }
  }
  .cell-card {
    width: 290px;
    height: 220px;
    background-color: #FFFFFF;
    padding-left: 20px;
    padding-top: 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .proBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    &-card {
      width: 350px;
      height: 365px;
      border: 1px dashed #1935DE;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 48px;
        height: 18px;
        position: absolute;
        right: -60px;
        top: 170px;
        background-image: url('~@/assets/img/home/4c30c14199cf43c7f29990f012f081af2c8b04dc18b97-BhIPZk.png');
        background-size: contain;
      }
      &:last-child::before {
        display: none;
      }
    }
    .proBox-head {
      background-color: #1935DE;
      width: 100px;
      height: 40px;
      color: #fff;
      border-radius: 20px;
      text-align: center;
      line-height: 40px;
      box-sizing: border-box;
      margin-bottom: 10px;
    }
  }
  </style>
