import { render, staticRenderFns } from "./ERPsystem.vue?vue&type=template&id=76992672&scoped=true"
import script from "./ERPsystem.vue?vue&type=script&lang=js"
export * from "./ERPsystem.vue?vue&type=script&lang=js"
import style0 from "./ERPsystem.vue?vue&type=style&index=0&id=76992672&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76992672",
  null
  
)

export default component.exports